import React, { useEffect,useRef, useState } from "react";
// import Logotop from "../../images/logotop.png";
// import Kitchen from "../../images/kitchen.png";
// import Artisan from "../../images/Artisan.png";
// import Mimal from "../../images/Mimal.png";
import { toast } from "react-toastify";

import Love from "../../images/love.png";
import LoveRed from "../../images/love-red.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Winner from "../popups/Winner";
import api from "../../api";
import location from "../../images/loation.svg";
import Map from "../Map";
import Reel from "../Reel";
import { Store } from "../../StoreContext";
import { LoadScript } from "@react-google-maps/api";
import { Carousel } from 'react-responsive-carousel';
import { disableScroll} from '../home/scrollControl.js';
import Cookies from 'js-cookie'; // Import the js-cookie library



const Carousal = ({ sliderData, setSliderData, filterization }) => {
  const sliderRef = useRef();
  const [fetching, setFetching] = useState(false);
  const [carasoulSliderState,setCarasoulSliderState] = useState(0)
  const [carasoulSliderItems,setCarasoulSliderItems] = useState([])
  const [selectedCarousel,setSelectedCarousel] = useState(0)
  const [selectedIndex, setSelectedIndex] = useState(0); 
  const [reel, setReel] = useState(false);
  const [map, setMap] = useState(false);
  const { user } = Store();
  const [winner, setWinner] = useState(false);
  const [reelMedia, setReelMedia] = useState({
    media: null,
    logo: null,
    mediaType: null,
  });
  const [imagepath, setImagePath] = useState("");
  const [mediaPath, setMediaPath] = useState("");
  const [selectedIdForShare, setSelectedIdForShare] = useState(0)
  const [vendorIdForMap, setVendorIdForMap] = useState(0)
  const userId = Cookies.get('user_id'); // Retrieve user ID from cookies

  useEffect(() => {
    const savedFilter = Cookies.get(`selectedFilter_${userId}`);
    console.log(savedFilter)
    if (savedFilter === "alphabatically" || savedFilter === "newest") {
      fetchInitialOffers(savedFilter);
    } else if (savedFilter==='favourite') {
      
      fetchingFavouriteOffers();
    }else{
      fetchInitialOffers('newest');
    }

    // if (filterization !== 'favourite') {
    //   let filter = null;
    //   if (filterization === 'alphabatically') 
    //     filter = 'alphabatically';

    //   fetchInitialOffers(filter);
    // } else {
    //   fetchingFavouriteOffers();
    // }
  }, [user.id, filterization]);

  const fetchInitialOffers = (filter) =>{
    api("post", "/event_offers/list", { user_id: user.id, filter })
    .then((response) => {
      // sliderRef?.current?.slickGoTo(0);
      sliderRef?.current?.moveTo(0);
      console.log("I am current",sliderRef?.current)
      console.log("Success Data:", response.data);
      setImagePath(response?.data?.r_logo_path);
      setMediaPath(response?.data?.media_content_path);
      let length = response?.data?.data?.length;
      setCarasoulSliderItems(length >=3 ? [...Array(3).keys()] : [...Array(length).keys()]  )
      setCarasoulSliderState(0)
      setSliderData([...response?.data?.data]);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
  }
 
  const fetchingFavouriteOffers = () =>{
    api("post", "/favourite/all_list", { user_id: user.id,})
    .then((response) => {
      // sliderRef?.current?.slickGoTo(0);
      sliderRef?.current?.moveTo(0);
      console.log("Success Favourite Data:", response.data);
      setImagePath(response?.data?.r_logo_path);
      setMediaPath(response?.data?.media_content_path);
      let fav_data = response?.data?.data
      let data = fav_data.map(item=> ({...item?.events,favourite:[{id:item?.id,user_id:item?.user_id}]}) )
      let length = data?.length;
      setCarasoulSliderItems(length >=3 ? [...Array(3).keys()] : [...Array(length).keys()]  )
      setCarasoulSliderState(0)
      setSliderData(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
  }



  const settings = {
    // dots: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite:false,
    // autoplay: true,
    // autoplaySpeed: 5000, 
    arrows: false,
    afterChange:(current) => handleItemChange(current),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  
  const settings_1 = {
    showIndicators : false,
    showArrows:false,
    onChange: (current)=> handleItemChange(current),
    infiniteLoop:false,
    showStatus:false,
    showThumbs:false,
    transitionTime:700,
    selectedItem:selectedCarousel
  }


  // Handle Item Change
  const handleItemChange = (current) =>{
    let remaining = sliderData?.length - current
    let modules = current % 3 ;
    setCarasoulSliderState(modules)
    if(sliderData?.length % 3 !== 0 && modules === 0) { 
      if(remaining < 3  ){
        setCarasoulSliderItems([...Array(remaining).keys()])
      }
    }else if(sliderData?.length > 3 && modules === 2   ){
        setCarasoulSliderItems([...Array(3).keys()])
    }

    if(!fetching && current === sliderData.length - 3){
      setFetching(true);
      fetchByPagination(sliderData.length)
    }
  }

  const fetchByPagination = (offset) =>{

    if(filterization !== 'favourite'){
      let filter = null;
      if(filterization === 'alphabatically') 
        filter = 'alphabatically'
      api("post", "/event_offers/list", { user_id: user.id,offset, filter })
      .then((response) => {
        console.log("Pagination",response?.data?.data)
        // setSliderData(pre => [...pre, ...response?.data?.data]);
        setSliderData(prevData => prevData.concat(response?.data?.data || []));

      })
      .catch((error) => {
        console.error("Error:", error);
      }).finally(()=> setFetching(false));
    }
    else{
      api("post", "/favourite/all_list", { user_id: user.id, offset})
    .then((response) => {
      console.log("Success Favourite Data:", response.data);
      let fav_data = response?.data?.data
      let data = fav_data.map(item=> ({...item?.events,favourite:[{id:item?.id,user_id:item?.user_id}]}) )
      // setSliderData(pre => [...pre,...data]);
      setSliderData(prevData => prevData.concat(data || []));

    })
    .catch((error) => {
      console.error("Error:", error);
    }).finally(()=> setFetching(false));
    }

  }

  const handleWinner = (id) => {
    const cookieName = `slider_${id}_for_${userId}`;
    const cookieValue = Cookies.get(cookieName);
    console.log(cookieValue)
    if (!cookieValue) {
      api('post', '/event_offers/counter', { id })
        .then((response) => {
          // On success, save cookie for 60 minutes
          Cookies.set(cookieName, true, { expires: 1 / 24 });
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  
    setSelectedIdForShare(id);
    setWinner(!winner);
    document.body.style.overflow = "hidden";
  };
  const handleMap = (index) => {
    // setVendorIdForMap(id)
    setSelectedIndex(index);
    setMap(true);
    document.body.style.overflow = "hidden";
  };
  const handleReel = (media, logo, url, title) => {
    document.body.style.overflow = "hidden";
    disableScroll();
    setReelMedia({ media, logo, url, title });
    setReel(!reel);
  };
  

  const handleAddFavorite = (eventId) => {
    api("post", "/favourite/store", { user_id: user.id, event_id: eventId })
      .then((response) => {
        console.log("Added to favorites:", response.data);
        setSliderData(pre => pre.map(item => 
          item.id === eventId ? { ...item, favourite: [response?.data?.data] } : item
        ));
        toast.success("Business added to your favorites list");
      })
      .catch((error) => {
        console.error("Error adding to favorites:", error);
      });
  };


  const removeItemFromFavouriteList = (fav_id, offer_id) =>{
    api("post", "/favourite/remove", { id: fav_id, })
      .then((response) => {
        console.log("Remove from favorites:", response.data);
        setSliderData(pre => pre.map(item => 
          item.id === offer_id ? { ...item, favourite: [] } : item
        ));
        toast.success("Business removed from your favorites list");
      })
      .catch((error) => {
        console.error("Error adding to favorites:", error);
      });
  }
 


  const setLogoBorderOnMedia = (media) =>{
    if(media){
      return {
        border:'4px solid #d00d4f', width:"120px", height:'120px', borderRadius: '100%'
      }
    }else{
      return{
        border:'4px solid #C9C9C9',width:"120px", height:'120px', borderRadius: '100%'
      }
    }
  }

  return (
    <>
      {winner && <Winner setWinner={setWinner} offer_id={selectedIdForShare} />}
      {winner && (
        <div
          className="side_bar_background"
          onClick={() => {
            setWinner(false);
            document.body.style.overflow = "visible";
          }}
        />
      )}
      {reel && <Reel reelMedia={reelMedia} setReel={setReel} />}
      {reel && (
        <div
          className="side_bar_backgroundss"
          onClick={() => {
            setReel(false);
            document.body.style.overflow = "visible";
          }}
        />
      )}
      {map && <Map setMap={setMap}  vendor_id={sliderData[selectedIndex]?.vendor_id}
    phone={sliderData[selectedIndex]?.phone}
    address={sliderData[selectedIndex]?.address}
    city={sliderData[selectedIndex]?.city}
    state={sliderData[selectedIndex]?.state}
    zipcode={sliderData[selectedIndex]?.zipcode}
    />}
  {map && (
    <div
      className="side_bar_background"
      onClick={() => {
        setMap(false);
        document.body.style.overflow = "visible";
      }}
    />
  )}
      <div class="slider_back">
        {sliderData.length > 0 ? (
          <>
          <Carousel {...settings_1} className="slider_container" 
            ref={sliderRef}>
            {sliderData.map((slide,index) => (
              <div className="slider_box" key={slide.id}>
                <div className="Slider_logo">
                    <img src={imagepath + slide?.r_logo} alt="" style={setLogoBorderOnMedia(slide?.media_type)}
                    onClick={() =>
                      {
                        slide?.media_type && 
                        handleReel(
                        mediaPath + slide?.media_content,
                        imagepath + slide?.r_logo,
                        slide?.media_type,
                        slide?.rest_title
                      )}
                    } />
                  <div className="location" onClick={()=>{
                       handleMap(index)
                      }}>
                    <img src={location}
                     alt=""  />
                  </div>
                </div>
                <div className="slide_head">
                  <span>{slide?.rest_title}</span>
                </div>
                <div className="slider_heading_price">
                  <div className="slider_heading">
                    <span style={{textAlign:'left'}}>{slide?.offer_title}</span>
                  </div>
                  <div className="Price">
                    <p>
                      <span style={{ display: "flex", alignItems:'center' }}>
                        {slide?.new_price !== null &&
                        slide?.old_price !== null && slide?.offer_type === 'old_new' ? (
                          <div className="price_container">
                            <span className="old_price" style={{textDecoration:'line-through',color:"#5f5f5f",marginRight:'4px',fontSize:'20px'}}>{slide.old_price}$</span>     
                            <span className="new_price">{slide.new_price}$</span>                            
                          </div>
                        ) : slide?.discount_amount !== null && slide?.offer_type === 'off' ? (
                          <span>{slide.discount_amount}% off</span>
                        ) : slide?.offer_type !== null ? (
                          <span>{slide.offer_type}</span>
                        ) : (
                          <span>No Price Available</span>
                        )}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="slide_detail">
                  <p style={{textAlign:'left'}}>{slide?.description}</p>
                </div>
                <div className="slide_icon_button">
                  <div
                    className="love_icon"
                    onClick={() => {
                      slide?.favourite.length > 0 && slide?.favourite?.[0].user_id === user.id ?
                      removeItemFromFavouriteList(slide?.favourite?.[0].id, slide.id):
                      handleAddFavorite(slide.id)
                      
                      }}
                  >
                    <img src={
                      slide?.favourite.length > 0 && slide?.favourite[0].user_id === user.id ?
                      LoveRed:
                      Love
                      } alt="" className="love_icon_image" />
                  </div>
                  <div className="slidebutton">
                    <button onClick={()=>handleWinner(slide?.id)}>Redeem Now</button>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
               
          <div className="item-bar-container">
                {
                  carasoulSliderItems.map((item,index)=>(
                    <div  className="item-bar" 
                      style={{
                        backgroundColor: carasoulSliderState === item ?
                        '#d00d4f' : '#C9C9C9'
                      }}
                    />
                  ))
                  }
            </div>

          </>
        ) 
        :
        filterization === 'favourite' ? 
        <div  className="empty_favourite" >
            You have not saved any favorites yet.
        </div>
        : (
          <div className="slider_box empty_slider_box" key={sliderData[0]?.id}>
            <div className="Slider_logo">
              <div className="logo_top dummy_top" >
                {/* <img src={imagepath + sliderData[0]?.r_logo} alt="" /> */}
                {/* <div className="location">
                  <img src={location} alt="" onClick={handleMap} />
                </div> */}
              </div>
            </div>
            <div className="slide_head">
              <span>{sliderData[0]?.rest_title}</span>
            </div>
            <div className="slider_heading_price">
              <div className="slider_heading">
                <span>{sliderData[0]?.offer_title}</span>
              </div>
              <div className="Price">
                <p>
                  <span>{sliderData[0]?.old_price}</span>{" "}
                  {sliderData[0]?.new_price}
                </p>
              </div>
            </div>
            <div className="slide_detail">
              <p>{sliderData[0]?.description}</p>
            </div>
            <div className="slide_icon_button">
              <div className="love_icon" onClick={handleAddFavorite}>
                <img src={Love} alt="" className="love_icon_image"/>
              </div>
              <div className="slidebutton">
                <button >Redeem Now</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Carousal;
