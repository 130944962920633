// src/components/VideoPlayer.jsx
import React, { useState } from 'react';
import styled from 'styled-components';

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: auto;
  text-align: center;
`;

const Video = styled.video`
  width: 100%;
  height: auto;
`;

const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 10px 15px;
  padding-left: 16px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
`;

const VideoPlayer = ({ src, poster, style }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = React.createRef();

  const handlePlay = () => {
    videoRef.current.play();
    setIsPlaying(true);
  };

  return (
    <VideoWrapper>
      <Video ref={videoRef} style={style} poster={poster} controls={isPlaying}>
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </Video>
      {!isPlaying && (
        <PlayButton onClick={handlePlay}>
          ▶
        </PlayButton>
      )}
    </VideoWrapper>
  );
};

export default VideoPlayer;
