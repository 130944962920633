import React, { useState, useEffect } from 'react';
import Backbtn from "../../images/back.png";
import { Link, useLocation } from 'react-router-dom';
import winner from '../../images/winner.png';
import api from "../../api";
import { toast } from "react-toastify";
import Cookies from 'js-cookie';
import { useNavigate  } from 'react-router-dom';

const VerifyEmailScreen = () => {
    const location = useLocation();
    const [userId, setUserId] = useState(null);
    const [cooldown, setCooldown] = useState(0);
    const [resendCount, setResendCount] = useState(0);
    let navigate = useNavigate();

    useEffect(() => {
        // Debug: Log the location.state to verify its contents
        console.log("Location State:", location.state);

        const id = location.state?.id || location.state?.data?.id;
        if (id) {
            setUserId(id);
        } else {
            console.error("User ID not found in location.state");
        }

        const storedCount = Cookies.get('resendCount') || 0;
        const lastAttempt = Cookies.get('lastAttempt');
        const currentTime = Date.now();

        if (lastAttempt) {
            const elapsedTime = (currentTime - parseInt(lastAttempt)) / 1000;
            if (elapsedTime < 60) {
                setCooldown(60 - elapsedTime);
            }
        }

        setResendCount(parseInt(storedCount));
    }, [location.state]);

    useEffect(() => {
        let timer;
        if (cooldown > 0) {
            timer = setInterval(() => {
                setCooldown(prevCooldown => prevCooldown - 1);
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [cooldown]);

    const handleResendEmail = () => {
        if (cooldown > 0) {
            toast.error(`Please wait ${Math.ceil(cooldown)} seconds before resending the email.`);
            return;
        }

        if (resendCount >= 5) {
            toast.error("You have reached the maximum limit of resend attempts for today.");
            return;
        }

        if (userId) {
            api("post", `/email/verify/sendcode`, { id: userId })
                .then((response) => {
                    if (response.status === 200) {
                        toast.success("Verification email resent. Please check your inbox.");
                        const newCount = resendCount + 1;
                        setResendCount(newCount);
                        Cookies.set('resendCount', newCount, { expires: 1 });
                        Cookies.set('lastAttempt', Date.now(), { expires: 1 });
                        setCooldown(60);
                    } else {
                        toast.error("Failed To Resend Verification Email.");
                    }
                })
                .catch((error) => {
                    console.error("Resend Email Error:", error);
                    toast.error("Failed To Resend Verification Email");
                });
        } else {
            toast.error("User ID not found. Please try signing up again.");
            navigate('/login');
        }
    };

    return (
        <div className="otp_form">
            <Link to="/main">
                <img width='41' src={Backbtn} alt="" />
            </Link>

            <div className="verify_container">
                <h1>Verify Your Email</h1>
                <p className="description">Please verify your email. We have sent you an email to verify.</p>
                <img src={winner} alt="Email Verification" className="image" />
                <p className="additional-description">
                    No email? Please check spam, <strong onClick={handleResendEmail}>resend email</strong>, or try signing up with another email address.
                </p>
            </div>
        </div>
    );
};

export default VerifyEmailScreen;
