import React from 'react'
import Cross from "../../images/cross.png"
import Aboutimg from "../../images/aboutimg2.jpeg"
import Logos from "../../images/logose.svg"
import AboutTennerVid from "../../images/AboutTennerVid.png"
import Logoes from "../../images/logost.svg"
import TennerVideo from "../../videos/About Tenner (Main kid on left).mp4"
import VideoPlayer from '../common/VideoPlayer';

const Abouttenner = ({setAbout}) => {
  return (
    <>
    <div className="about_tenner">
    <div className="about_heading">
            <h6 style={{    position: 'relative',right:'15px'}}>About Tenner</h6>
            <div className="about_cross_icon">
            <img src={Cross} style={{width:'26px'}} alt="" 
             onClick={() => {
              setAbout(false);
              document.body.style.overflow = 'visible';
            }}/>
            </div>
            </div>
        
        <div className="about_tenner_img">
        <div className="about_logo">
            <div className="about_logo_top">
            <img src={Logoes} alt="" />  
            <img src={Logos} alt="" />
            </div>
            <div className='aboutlogo_down'>
            <span>Local Deals</span>
            </div>
            </div> 
            <div className="about_video">
              <VideoPlayer
                style={{borderRadius: '10px',marginTop: '-1px',marginLeft: '-1px'}}
                src={TennerVideo}
                poster={AboutTennerVid}
              />
            </div>
      
        </div>
        <div className="about_icon_text">
            <div className="about_imgpart">
              <img width="120" src={Aboutimg} alt="" />
            </div>
            <div className="about_text">
                <p>Be on the lookout for this sticker all across Lafayette! If you see it, then scan the QR code when walking in to see how you can save money!</p>
            </div>
        </div>
        </div>
    </>
  )
}

export default Abouttenner