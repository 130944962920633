import React, { useContext, useEffect, useState } from 'react'
import api from "../../api";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ForgetPasswordContext } from '../../utils/context'
import Backbtn from "../../images/back.png";

const Otpsec = () => {
  const { forgetPasswordEmail } = useContext(ForgetPasswordContext)
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: forgetPasswordEmail,
    otp_code: "",
  });

  if (!forgetPasswordEmail) {
    navigate('/forget')
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Handle form submission logic here
    api("post", "/match_otp", formData)
      .then((response) => {
        if (response.status === 200) {
          toast.success("OTP verified successfully")
          navigate("/Confirmpassword");
        }
      })
      .catch((error) => {
        // Handle error here
        toast.error("Invalid OTP...")
        console.error("Error:", error);
      });
  };
  return (

    <>
      <div className="otp_form">
        <Link to="/main">
          <img width='41' src={Backbtn} alt="" />
        </Link>
          <div className="forget_top">
            <span>Email Sent</span>
            <p>
            An email has been sent to you containing a One Time Password (PIN). Please enter it below to proceed with changing your password.
            </p>
          </div>
          <div className="otp_fiels">
            <input
              type="text"
              placeholder="Enter PIN"
              name="otp_code"
              value={formData.otp_code}
              onChange={handleChange}

            />
          </div>
          <div className="otp_button">
            <button onClick={handleSubmit} disabled={!formData.otp_code} >Verify</button>
          </div>
      </div>
    </>

  )
}

export default Otpsec
