// scrollControl.js

function preventDefault(e) {
    e.preventDefault();
}

export function disableScroll() {
    document.body.addEventListener('touchmove', preventDefault, { passive: false });
    document.body.style.overflow = 'hidden'; // Disable scrolling on desktop
}

export function enableScroll() {
    document.body.removeEventListener('touchmove', preventDefault, { passive: false });
    document.body.style.overflow = ''; // Enable scrolling on desktop
}

function preventHorizontalScroll(e) {
    // Prevent default if horizontal scroll is attempted
    if (e.deltaX !== 0) {
        e.preventDefault();
    }
}

export function disableHorizontalScroll(element) {
    element.addEventListener('wheel', preventHorizontalScroll, { passive: false });
    element.style.overflowX = 'hidden';
}

export function enableHorizontalScroll(element) {
    element.removeEventListener('wheel', preventHorizontalScroll, { passive: false });
    element.style.overflowX = '';
}