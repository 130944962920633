import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import main from "../../images/main.jpg"
import Logo from "../../images/logo_circle.png";
import Flag from "../../images/acadian-flag.webp";
import Contact from "../popups/Contact";
import VideoPlayer from '../common/VideoPlayer';
import TennerVideo from "../../videos/Tenner main.mp4";
import api from "../../api";

const Main = () => {
  const [contact, setContact] = useState(false);
  const [menu, setMenu] = useState(false);
  //check maintenanceMode 
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  useEffect(() => {
      api("get", "/check-maintenance")
      .then((response) => {
        setMaintenanceMode(response.data.maintenance_mode === 1);
      })
      .catch((error) => {
        console.error('There was an error fetching the maintenance mode!', error);
      });
  }, []);
  //----check maintenanceMode

  const handleContact = () => {
    window.open('https://business.tennerlocaldeals.com', '_blank');
    // setContact(true);
    // setMenu(false);
    // document.body.style.overflow = "hidden";
  };
  if (!maintenanceMode) {
    return (
      <>
        <div className="main_div">
          <div className="main_div_inner">
            <div style={{width:'100%', position:'fixed', top:'2vh',left:'2vh'}} className="flag-div">
              <img src={Flag} alt="" style={{width:'80px', float:'left'}} />
            </div>
            <div className="logo_img">
              <img src={Logo} alt="" />
            </div>
            <div className="main_text">
            <h1>Save <span className="highlight">Money</span> in Lafayette</h1>
          </div>
          <div className="main_image">
            {/* <img src={main} alt="Main" /> */}
            <VideoPlayer
              style={{borderRadius: '10px',marginBottom: '20px'}}
              src={TennerVideo}
              poster={main}
            />
          </div>
            <div className="buttons">
              <div className="button_first" style={{ "text-decoration": "none" }}>
                <Link to="/signup" className="linkRedirect" style={{ "text-decoration": "none" }}>
                  <button >Create an account</button>
                </Link>
              </div>
              <div className="button_second">
                <Link to="/login" className="linkRedirect">
                  <button>Log In</button>
                </Link>
              </div>
              <div className="main_text2">
        <span onClick={handleContact}>  Are You a Business? </span>
        </div>

        {contact && <Contact setContact={setContact} setMenu={setMenu}  />}
        {contact && (
          <div
            className="side_bar_backgrounds"
            onClick={() => {
              setContact(false);
              setMenu(!menu);
        
              document.body.style.overflow = "visible";
            }}
          />
        )}
        
            </div>
          </div>
        </div>
      </>
    );
  }

};

export default Main;
