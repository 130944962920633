import React, { useState } from "react";
import { toast } from "react-toastify";

import Cross from "../../images/cross.png";
import api from "../../api";
import { Store } from "../../StoreContext";
import Cookies from "js-cookie";


const Avatar = ({ setAvatar, avatarImages, selectedAvatar }) => {
  const { user } = Store();
  console.log("🚀 ~ Avatar ~ user:", user.id);
  const userId = Cookies.get('user_id'); // Retrieve user ID from cookies

  const [selectedImageIndex, setSelectedImageIndex] = useState(selectedAvatar);
  console.log("🚀 ~ Avatar ~ selectedImageIndex:", selectedImageIndex);

  const handleImageChange = (imageIndex) => {
    setSelectedImageIndex(imageIndex);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    api("get", `/UpdateuserAvatar/${user.id}/${selectedImageIndex}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Image Updated Successfully");

          let userData = {
            "message": "success",
            "user_avatar": selectedImageIndex
        }
          Cookies.set("userData_"+userId, JSON.stringify(userData), { expires: 1 });
          setAvatar(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div className="avatar">
        <div className="avatar_heading">
          <h6>Choose Your Avatar</h6>
          <div className="avatar_cross_icon">
            <img
              src={Cross} style={{width:'26px'}}
              alt=""
              onClick={() => {
                setAvatar(false);
                document.body.style.overflow = "visible";
              }}
            />
          </div>
        </div>
        <div className="avatar-imgs">
          {/* Map over the avatarImages array to render the images */}
          {avatarImages.map((avatar, index) => (
            <img
              key={index}
              src={avatar}
              alt={`Avatar ${index + 1}`}
              onClick={() => handleImageChange(index)}
              style={{
                border: selectedImageIndex == index ? "3px solid #d00D4F" : "none",
                borderRadius: selectedImageIndex == index ? "50%" : "none",
            }}
            />
          ))}
        </div>
        <div className="avatar_button">
          <button onClick={handleSubmit}>Save</button>
        </div>
      </div>
    </>
  );
};

export default Avatar;
