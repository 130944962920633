// src/components/Maintenance.js
import React from 'react';
import Logo from "../images/logo_circle.png";
import Laptop from "../images/laptop.png";


const Maintenance = () => (
  <div style={{
    padding: 0,
    margin: 0,
    width: '100%',
    height: '100vh',
    background: 'linear-gradient(180deg, #FF6865 0%, #D00D4F 100%)',
    color: '#fff',
    fontFamily: 'Open Sans',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column'
    
  }}>
    <article style={{ display: 'block', padding: '25px', margin: '0 auto' }}>
      <div>
        <img style={{width:'160px'}} src={Logo} alt="" />
      </div>
      <h1 style={{ fontWeight: '200',fontSize: '1.3rem', color:'#fff' }}>We’ll be back soon!</h1>
      <div style={{fontFamily: 'Open Sans',fontWeight:'100'}}>
        <p style={{fontSize: '14px'}}>Sorry for the inconvenience. We’re performing some maintenance at the moment. If you need to you can always follow us on <a href="https://www.facebook.com/TennerLocalDeals/" style={{ color: '#fff', fontWeight: 'bold', textDecoration:'underline' }}>Facebook</a> for updates, otherwise we’ll be back up shortly!</p>
        <p>— The Tenner Local Deals Team</p>
      </div>
      <div>
        <img style={{width:'100%'}} src={Laptop} alt="" />
      </div>
    </article>
  </div>
);

export default Maintenance;
