import React, { useEffect } from "react";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Google from "../../images/gmail.png";
import Facebook from "../../images/Facebook.png";
import api from "../../api";
import { gapi } from 'gapi-script';
import { UpdateStore } from "../../StoreContext";
import Cookies from 'js-cookie'; // Import the js-cookie library

const Links = ({signup}) => {
  const nav = useNavigate();
  const updateStore = UpdateStore();


  useEffect(()=>{
    function start(){
      gapi.client.init({
        clientId: googleLoginClientId,
        scope:''
      })
    }

    gapi.load('client:auth2',start)
  },[])

  const responseGoogle = (response) => {
    console.log("Google Response", response)
    // if (response) {
    //   toast.success("You have successfully");
    //   nav("/home");
    // }
    socialLoginWithCred(
      {
        fname:response?.profileObj?.name,
        provider:"GOOGLE",
        provider_id:response?.googleId,
        email:response?.profileObj?.email 
      }
    )
  };

  const socialLoginWithCred = (data) =>{
    api("post", "/social_login", data)
    .then((response) => {
      // Handle successful response here

      if (response.status === 200) {
        // toast.success("You have successfully logged in");
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response?.data.data));
        Cookies.set('user_id', response?.data?.data.id); // Set user ID in cookies
        updateStore({ loggedIn: true, user: response?.data.data });
        nav("/home");
      }
    })
    .catch((error) => {
      // Handle error here
      console.error("Error:", error);
      return toast.error("This email address is already use to your another account");
    });
  }

  const failureGoogleResponse = (response) =>{
    console.log("Failure response",response)
  }

  // const responseFacebook = (response) => {
  //   console.log("facebook response", response);
  //   console.log("fb token", response.tokenId);

  //   api("post", "/login")
  //     .then((response) => {
  //       console.log("🚀 ~ .then ~ response:", response);
  //       // Handle successful response here

  //       if (response.status === 200) {
  //         toast.success("You have successfully logged in");
  //         localStorage.setItem("token", response.data.token);
  //         localStorage.setItem("user", response?.data.data);

  //         nav("/home");
  //       }
  //     })
  //     .catch((error) => {
  //       // Handle error here
  //       console.error("Error:", error);
  //       return toast.error("Incorrect Password");
  //     });
  // };

  const successFacebookResponse = (response) =>{
    console.log("facebook success", response);
    
    socialLoginWithCred({
      fname:response?.name,
        provider:"FACEBOOK",
        provider_id:response?.id,
        // email:response?.email ||  'abc@gmail.com' 
    })
  }

  const buttonStyle = {
    display: "flex",
    width: "100%",
    height: "8vh",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    border: "1.5px solid #D0D0D0",
    color: "black",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "24px",
    // textTransform: "lowercase",
    backgroundColor: "#FFFFFF",
  };

  const GoogleButton = ({ onClick }) => (
    <button onClick={onClick} style={buttonStyle}>
      <img
        src={Google}
        alt="Google"
        style={{
          marginRight: "5px",
          width: "7%",
          // border: "1px solid #D0D0D0",
        }}
      />
      {signup ? 'Sign up':'Sign in'} with Gmail
    </button>
  );

  const FacebookButton = ({ onClick }) => (
    <button onClick={onClick} className="kep-login-facebook">
      <img
        src={Facebook}
        alt="Facebook"
        style={{
          marginRight: "5px",
          border: "1px solid #D0D0D0",
          width: "24px",
          height: "24px",
        }}
      />
      {signup ? 'Sign up':'Sign in'} with Facebook
    </button>
  );


  // const googleLoginClientId = '912694794516-7e05em8tofhqcs5hi9080ajm76hhfjj7.apps.googleusercontent.com' //old
  const googleLoginClientId = '461731399526-cf1arogikujv59dj6r8rs9k7srpai95r.apps.googleusercontent.com' //new

  return (
    <div className="signup_sec2">
      <div className="or">
        <span className="orline"></span>
        <p>or</p>
        <span className="orline"></span>
      </div>
      <div className="social_buttons">
        <GoogleLogin
          // clientId="7274686233-aagcdodn56aa0bs169mnq1cuf031gt34.apps.googleusercontent.com"
          clientId={googleLoginClientId}
          onSuccess={responseGoogle}
          onFailure={failureGoogleResponse}
          cookiePolicy={"single_host_origin"}
          render={(renderProps) => (
            <GoogleButton onClick={renderProps.onClick} />
          )}
        />
        {/* <FacebookLogin
          // appId="313958924639588"
          // appId="1541581509726318"
          appId="1135057067681674"
          autoLoad={false}
          fields="name, email, picture"
          scope="public_profile,email"
          
          callback={successFacebookResponse}
          isMobile={false}
          icon="fa-facebook"
          textButton={`${signup ? 'Sign up' : 'Sign in'}`.toLowerCase() + ' with Facebook'}
          style={{textTransform: 'lowercase' }}
          render={(renderProps) => (
            
            <FacebookButton onClick={renderProps.onClick} style={{ textTransform: 'lowercase' }} />
          )}
        /> */}
      </div>
    </div>
  );
};

export default Links;
