import React, { useState, useEffect } from "react";
import { disableScroll, enableScroll } from '../components/home/scrollControl.js';
// import image from "../images/image1.png";

import Cross from "../images/cross.png";

const Reel = ({ setReel, reelMedia }) => {
  const [progressComplete, setProgressComplete] = useState(false);
  const [videoDuration, setVideoDuration] = useState(5)

  const handleAnimationEnd = () => {
    // Update state to indicate that the progress is complete
    
    setProgressComplete(true);
    setReel(false);
    document.body.style.overflow = "visible";
    enableScroll();
  };

  useEffect(() => {
    if(reelMedia?.media)
    {
      const video = document.createElement('video');
      video.src = reelMedia?.media;
      const onMetadataLoaded = () => {
      setVideoDuration(video.duration);
      // video.removeEventListener('loadedmetadata', onMetadataLoaded);
      };
      video.addEventListener('loadedmetadata', onMetadataLoaded);
      return () => {
        video.removeEventListener('loadedmetadata', onMetadataLoaded);
      }
  }
  }, [reelMedia?.media]); 


  const getAnimationTime = (type, duration)=>{
    if(type.includes('video')){
      return {
        animation: `progressAnimation ${duration}s ease-in-out forwards`
      }
    }

    return {
      animation: 'progressAnimation 5s ease-in-out forwards'
    }
  }
  
  return (
    <>
      <div className="custom-container">
        
      {progressComplete ? null : 
          reelMedia?.url?.includes('image') ?
          <img
          className="custom-img video_bg"
          src={reelMedia.media}
          alt="Placeholder"
        />
        :
        <video className="custom-img video_bg" autoPlay >
          <source src={reelMedia.media} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      }
        <div className="gradient-overlay" />
        <div className="progress-line">
          <div className="progress-bar-background" />
          <div
          className="progress-bar"
          style={getAnimationTime(reelMedia?.url, videoDuration)}
          onAnimationEnd={handleAnimationEnd}
        ></div>
        </div>
        <div className="logo-container">
          <div className="avatar-container">
            <div className="reel_avatar">
              <div className="inner-avatar">
                <div className="outer-avatar" />
                <img
                  className="avatar-image"
                  src={reelMedia.logo}
                  alt="Avatar"
                />
              </div>
            </div>
            <div className="title">{reelMedia?.title}</div>
          </div>
          <div className="close-button">
            <img
              src={Cross}
              alt=""
              onClick={() => {
                setReel(false);
                document.body.style.overflow = "visible";
                enableScroll();
              }}
              style={{width:'26px'}}
            />
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
};

export default Reel;
