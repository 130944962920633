import React, { useEffect, useState } from "react";
import Facebook from "../common/Links";
import Backbtn from "../../images/back.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../api";
import { UpdateStore } from "../../StoreContext";
import "@fortawesome/fontawesome-free/css/all.css";
import Cookies from 'js-cookie'; // Import the js-cookie library

const Signin = () => {
  const navigate = useNavigate();
  const updateStore = UpdateStore();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.email || !formData.password) {
      return toast.error("Please fill in both email and password.");
    }

    api("post", "/login", formData)
      .then((response) => {
        if (response.status === 200) {
          if (!response?.data?.data?.email_verified_at) {
            api("post", `/email/verify/sendcode`, { id: response?.data?.data.id })
              .then((verifyResponse) => {
                if (verifyResponse.status === 200) {
                  return navigate("/verify-email", { state: { id: response?.data?.data.id } });
                } else {
                  return toast.error("Failed to send verification code. Please try again.");
                }
              })
              .catch((error) => {
                console.error("Verification Code Error:", error);
                return toast.error("Failed to send verification code. Please try again.");
              });
          } else {
            // toast.success("You have successfully logged in");
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("user", JSON.stringify(response?.data.data));
            Cookies.set('user_id', response?.data?.data.id); // Set user ID in cookies
            updateStore({ loggedIn: true, user: response?.data.data });
            navigate("/home");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        return toast.error("Incorrect Password");
      });
  };

  useEffect(() => {
    setFormData({
      email: "",
      password: "",
    });
  }, []);

  return (
    <div className="sigin_form">
      <Link to="/main">
      <img width='41' src={Backbtn} alt="" />
      </Link>
      <form>
        <div className="signin_top">
          <p>Sign In with your email</p>
        </div>
        <div className="signin_input_fields">
          <input
            type="text"
            placeholder="Enter Your Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <div className="password-input-container">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Enter Your password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            <i
              className={`password-toggle-icon ${
                showPassword ? "fas fa-eye-slash" : "fas fa-eye"
              }`}
              onClick={togglePasswordVisibility}
            />
          </div>
        </div>
        <div className="forgotpass">
          <Link to="/forget" className="linkRedirect">
            <span>Forget password?</span>
          </Link>
        </div>
        <div className="signin_button">
          <button type="submit" onClick={handleSubmit}>
            Sign In
          </button>
        </div>
        <div>
          <Facebook />
        </div>
        <div className="signin_form_end">
          <p>
            Don’t have an account?
            <Link to="/signup" style={{ "text-decoration": "none" }}>
              <span> Sign Up</span>
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Signin;
