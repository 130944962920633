import { useEffect, useState } from "react";
import Menubutton from "../../images/menu.svg";
import Filter from "../../images/mi_filter.svg";
import Logo from "../../images/headerLogo.png";
import Logos from "../../images/logose.svg";
import Logoes from "../../images/logost.svg";
import Dinner from "./Dinner";
import Slider from "./Carousal";
import { toast } from "react-toastify";
import Menu from "../../components/popups/Profile";
import Filters from "../../components/popups/Filter";
import Contact from "../popups/Contact";
import Terms from "../popups/Term";
import api from "../../api";
import Cookies from 'js-cookie'; // Import the js-cookie library

const Home = () => {
  const [isToastVisible, setToastVisible] = useState(false);
  const [menu, setMenu] = useState(false);
  const [sliderData, setSliderData] = useState([]);
  const [filters, setFilters] = useState(false);
  const [filterization, setFilterization] = useState("");
  const [contact, setContact] = useState(false);
  const [term, setTerm] = useState(false);
  const [termData, setTermData] = useState("");

  const userId = Cookies.get('user_id'); // Retrieve user ID from cookies
  // console.log(userId)

  const handleContact = () => {
    setMenu(!menu)
    setContact(!contact);
    document.body.style.overflow = "hidden";
  };

  useEffect(() => {
    const savedFilter = Cookies.get(`selectedFilter_${userId}`); // Retrieve filter setting from the cookie
    if (savedFilter) {
      setFilterization(savedFilter);
    } else {
      Cookies.set(`selectedFilter_${userId}`, "newest");
      setFilterization("newest");
    }
  }, [userId]);

  const handleTerm = async () => {
    await api("get", "/page/term_conditions")
      .then((response) => {
        setMenu(false);
        setTermData(response.data.data.content);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setTerm(!term);
    document.body.style.overflow = "hidden";
  };

  const handleMenu = () => {
    setMenu(!menu);
    document.body.style.overflow = "hidden";
  };

  const handleFilters = () => {
    setFilters(!filters);
    document.body.style.overflow = "hidden";
  };

  return (
    <>
      {menu && <Menu setMenu={setMenu} isToastVisible={isToastVisible} setToastVisible={setToastVisible}  
         handleContact={handleContact} handleTerm={handleTerm}
      />}
      {menu && (
        <div
          className="side_bar_background"
          onClick={() => {
            setMenu(false);
            document.body.style.overflow = "visible";
          }}
        />
      )}

      {filters && filterization !== "" && (
        <Filters
          setFilterization={setFilterization}
          filterization={filterization}
          setFilters={setFilters}
          userId={userId}
        />
      )}
      {filters && (
        <div
          onClick={() => {
            setFilters(false);
            document.body.style.overflow = "visible";
          }}
        />
      )}

      {contact && <Contact setContact={setContact} setMenu={setMenu} />}
      {contact && (
        <div
          className="side_bar_backgrounds"
          onClick={() => {
            setContact(false);
            setMenu(!menu);
            document.body.style.overflow = "visible";
          }}
        />
      )}

      {term && <Terms setTerm={setTerm} termData={termData} setMenu={setMenu} />}
      {term && (
        <div
          className="side_bar_backgrounds"
          onClick={() => {
            setTerm(false);
            setMenu(!menu);
            document.body.style.overflow = "visible";
          }}
        />
      )}

      <div className="Homepage">
        <div className="home_inner">
          <div className="home_top">
            <div className="home_logo">
              <img src={Logo} style={{width:'120px'}} alt="" />
            </div>
          </div>
          <div className="home_top_icon">
            <img src={Filter} style={{width:'30px',height:'100%',margin:'-5px'}} alt="" onClick={handleFilters} className='icons-style' />
            <img src={Menubutton} style={{width:'30px',height:'100%',margin:'-5px'}} alt="" onClick={handleMenu} className='icons-style' />
          </div>
        </div>
        <div style={{ marginTop: '-4rem' }}>
          {filterization !== "" && (
            <Slider sliderData={sliderData} setSliderData={setSliderData} filterization={filterization} />
          )}
        </div>
        <div>
          <Dinner />
        </div>
        {isToastVisible && <div className="toast">Copied to clipboard!</div>}
      </div>
    </>
  );
};

export default Home;
