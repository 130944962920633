import React, { useEffect, useState } from "react";
import Cross from "../../images/cross.png";
import Cookies from 'js-cookie'; // Import the js-cookie library

const Filter = ({ setFilterization, filterization, setFilters, userId }) => {
  const handleCrossIcon = () => {
    setFilters(false);
    document.body.style.overflow = "visible";
  };

  const handleFilter = (value) => {
    Cookies.set(`selectedFilter_${userId}`, value); // Store filter setting in a cookie
    console.log('filter set as '+value)
    setFilterization(value);
    handleCrossIcon();
  };

  const handleCheckboxColor = (value) => {
    if (filterization === value) {
      return {
        backgroundColor: "#d00d4f"
      };
    }
  };

  useEffect(() => {
    const savedFilter = Cookies.get(`selectedFilter_${userId}`); // Retrieve filter setting from the cookie
    if (savedFilter) {
      setFilterization(savedFilter);
    }
  }, [setFilterization, userId]);

  return (
    <div className="filter">
      <div className="filter_heading">
        <h6>Filter By</h6>
        <div className="filter_cross_icon">
          <img src={Cross} style={{width:'26px'}} alt="" onClick={handleCrossIcon} />
        </div>
      </div>
      <div className="filter_checkbox">
        <div className="checkbox" onClick={() => handleFilter("newest")}>
          <label className="checkbox_label" style={handleCheckboxColor("newest")}></label>
          <span className="label_text">Newest to Oldest</span>
        </div>
        <hr className="profle_hr" />
        <div className="checkbox" onClick={() => handleFilter("alphabatically")}>
          <label className="checkbox_label" style={handleCheckboxColor("alphabatically")}></label>
          <span className="label_text">Alphabetically</span>
        </div>
        <hr className="profle_hr" />
        <div className="checkbox" onClick={() => handleFilter("favourite")}>
          <label className="checkbox_label" style={handleCheckboxColor("favourite")}></label>
          <span className="label_text">My Favorites Only</span>
        </div>
        <hr className="profle_hr" />
      </div>
    </div>
  );
};

export default Filter;
