import React, { useEffect, useState } from "react";
import Avatar from "./Avatar";
import Contact from "./Contact";
import Terms from "./Term";
import { toast } from "react-toastify";
import Cross from "../../images/cross.png";
import Logout from "../../images/Logout.svg";
import Complain from "../../images/Complain.svg";
import Help from "../../images/Help.svg";
import Share from "../../images/share.svg";
import Pencile from "../../images/pencilback.svg";
import Pencil from "../../images/pencil.svg";
import api from "../../api";
import Avatar1 from "../../images/avatar1.svg";
import Avatar2 from "../../images/avatar2.svg";
import Avatar3 from "../../images/avatar3.svg";
import { useNavigate } from "react-router-dom";
import { Store } from "../../StoreContext";
import Cookies from "js-cookie";

const Profile = ({ setMenu, setToastVisible, handleContact, handleTerm }) => {
  const { user } = Store();
  const navigate = useNavigate();
  const [avatar, setAvatar] = useState(false);
  const [termData, setTermData] = useState("");
  const avatarImages = [Avatar1, Avatar2, Avatar3];

  const [userData, setUserData] = useState({});
  const [usersData, setUsersData] = useState({});
  const userId = Cookies.get('user_id'); // Retrieve user ID from cookies
  console.log("🚀 ~ Profile ~ userData:", userData?.user_avatar);


  const handlePencil = () => {
    setAvatar(!avatar);
    document.body.style.overflow = "hidden";
  };
  const [term, setTerm] = useState(false);

  const handleLogout = () => {
    // toast.success("Successfully logged out.");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    Cookies.remove("userData_"+userId);
    Cookies.remove("usersData_"+userId);
    navigate("/login");
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const storedUserData = Cookies.get("userData_"+userId);
        const storedUsersData = Cookies.get("usersData_"+userId);

        if (storedUserData && storedUsersData) {
          setUserData(JSON.parse(storedUserData));
          setUsersData(JSON.parse(storedUsersData));
        } else {
          const userResponse = await api("get", `/GetuserAvatar/${user.id}`);
          const profileResponse = await api("post", "/profile/user", { user_id: user.id });

          const userData = userResponse.data;
          const usersData = profileResponse.data;

          setUserData(userData);
          setUsersData(usersData);

          Cookies.set("userData_"+userId, JSON.stringify(userData), { expires: 1 });
          Cookies.set("usersData_"+userId, JSON.stringify(usersData), { expires: 1 });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchUserData();
  }, [!avatar]);

  const handleCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.position = "fixed";
    textArea.style.left = "-9999px";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  };

  const copyTextToClipboard = async () => {
    try {
      handleCopyTextToClipboard("Https://tennerlocaldeals.com");
      setToastVisible(true);
      setTimeout(() => {
        setToastVisible(false);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <>
      {avatar && (
        <Avatar
          setAvatar={setAvatar}
          avatarImages={avatarImages}
          selectedAvatar={userData?.user_avatar}
        />
      )}
      {avatar && (
        <div
          className="side_bar_backgrounds"
          onClick={() => {
            setAvatar(false);
            document.body.style.overflow = "visible";
          }}
        />
      )}
      <div className="profile">
        <div className="profile_cross_icon">
          <img
            src={Cross}
            style={{ width: "26px" }}
            alt=""
            onClick={() => {
              setMenu(false);
              document.body.style.overflow = "visible";
            }}
          />
        </div>
        <div className="profile_img_detail">
          <div className="profile_img">
            <div className="avatar_first">
              <img
                src={avatarImages[userData?.user_avatar]}
                alt=""
                style={{
                  border: "3px solid #d00D4F",
                  borderRadius: "50%",
                }}
              />
            </div>
            <div className="avatar_top">
              <div className="avatar_top_inner1">
                <img src={Pencile} alt="" />
              </div>
              <div className="avatar_top_inner">
                <img src={Pencil} alt="" onClick={handlePencil} />
              </div>
            </div>
          </div>
          <div className="profile_detail">
            {usersData.data && (
              <>
                <span className="name">{usersData.data.fname}</span>
                <span className="gmail">{usersData.data.email}</span>
              </>
            )}
          </div>
        </div>

        <div className="profile_center">
          <hr className="profle_hr" />
          <div className="contact_us">
            <img src={Complain} alt="" />
            <span onClick={handleContact}>Contact Us</span>
          </div>
          <hr className="profle_hr" />
          <div className="term_services">
            <img src={Help} alt="" />
            <span onClick={handleTerm}>Terms of Services</span>
          </div>
          <hr className="profle_hr" />
          <div className="linkRedirect">
            <div className="logout" onClick={handleLogout}>
              <img src={Logout} alt="Logout" />
              <span>Log Out</span>
            </div>
          </div>
          <hr className="profle_hr" />
        </div>
        <div className="profile_down">
          <div className="profile_paragraph">
            <p>Help us spread the word and pass on savings to your friends</p>
          </div>
          <div onClick={copyTextToClipboard} className="linkRedirect">
            <div className="profile_button">
              <button>
                <img src={Share} alt="" />
                Share
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
