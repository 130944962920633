import React from "react";
import Cross from "../../images/cross.png";
import InnerHTML from 'dangerously-set-html-content'

const Term = ({ setTerm, termData, setMenu }) => {
  return (
    <>
      <div className="terms">
        <div className="terms_heading">
          <h6>Terms of Service</h6>
          <div className="term_cross_icon">
            <img
              src={Cross} style={{width:'26px'}}
              alt=""
              onClick={() => {
                setTerm(false);
                document.body.style.overflow = "visible";
              }}
            />
          </div>
        </div>
        <div className="term_paragraph">
          
          <InnerHTML html={termData} />

        </div>
      </div>
    </>
  );
};

export default Term;
