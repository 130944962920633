

import React, { useEffect, useState } from 'react'
import Cross from "../images/cross.png"
import api from "../api";
import { toast } from "react-toastify";

const Map =  ({ setMap, vendor_id, phone, address ,city,state,zipcode})  => {
  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: "AIzaSyB5EIgTFHJi57gLhDCq6Vv3wUMG_xZCK24"
  // })
  // const lat = '31.5843';
  // const lon = '74.3828';
  
  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(false);
  const formatPhoneNumber = (phone) => {
    // Remove any non-numeric characters
    let cleanedNumber = phone.replace(/\D/g, "");
    cleanedNumber = cleanedNumber.replace('+', "");
    cleanedNumber = cleanedNumber.substring(1);

    // Extract area code, exchange, and subscriber number
    const areaCode = cleanedNumber.slice(0, 3);
    const exchange = cleanedNumber.slice(3, 6);
    const subscriberNumber = cleanedNumber.slice(6);
    // Format the phone number
    return `(${areaCode}) ${exchange}-${subscriberNumber}`;
  }
  useEffect(() => {
    // fetchData();
    // console.log(setMap, vendor_id, phone, address ,"dd")
  }, []);

  const handleCopyTextToClipboard = (text) => {
    // Create a temporary text area element
    const textArea = document.createElement("textarea");
    textArea.value = text;
  
    // Set styles to make copying work in various environments
    textArea.style.position = "fixed";  // Avoid scrolling to bottom
    textArea.style.left = "-9999px";    // Element is not visible
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      // Attempt to execute the copy command
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log(`Copying text command was ${msg}`);
    } catch (err) {
      console.error('Oops, unable to copy', err);
    }
  
    // Remove the temporary text area
    document.body.removeChild(textArea);
  };

  const copyLocationToClipboard = async () => {
    try {
      // await navigator.clipboard.writeText("https://google.com");
      handleCopyTextToClipboard(`${address}, ${city}, ${state}, ${zipcode}`)
      toast.success("Location Copied Successfully!");
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  
  // const fetchData = async () => {
  //   setLoader(true)
  //   api("get", `/GetVendorById/${vendor_id}`)
  //     .then((response) => {
  //       console.log("Success Data:", response?.data?.vendor);
  //       setData(response?.data?.vendor)
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     }).finally(() => setLoader(false))
  // };



  return (
    <>
    <div className="map_popup" style={{ overflow:"hidden" }}>
      <div className="map_cross_icon">
        <img
          src={Cross}
          style={{width:'26px'}}
          alt=""
          onClick={() => {
            setMap(false);
            console.log("Map clicked")
            document.body.style.overflow = 'visible';
          }}
        />
      </div>
      <div className='map_img'>
        <iframe
          height="250"
          style={{ border: 0,width:"100%" }}
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCJ_EsOqHiaQB23gHur_Y2GuEMfcMfQ47c&q=${address},+${city},${state}`}
        />
      </div>
      <div className='Map_div'>
        <div class="map_container">
          <div class="map_inner-div">
            <div class="titles">Location <i style={{color:'rgb(67, 67, 67)'}} onClick={() => {
              copyLocationToClipboard();
            }} class="fa fa-copy"></i></div>
          </div>
          <div class="inner-div" style={{fontFamily: 'Poppins',fontWeight: '400',color: '#434343',marginTop: '5px'}}>
            <div class="address" style={{maxWidth:'300px',whiteSpace:'nowrap',overflow:'scroll'}}>{address}</div>
            <div class="address">{city}, {state} {zipcode}</div>
          </div>
        </div>
        <div class="second-container">
          <div class="titles">Call Us</div>
          <div class="inner-div">
            <div>
              <span class="phone">Phone:</span>
              <span class="phone-number">  {formatPhoneNumber(phone)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>


  )
}

export default Map
