import React, { useState,useContext } from 'react'
import api from "../../api";
import {useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {ForgetPasswordContext} from '../../utils/context'


const Confirmpassword = () => {
  const { forgetPasswordEmail } = useContext(ForgetPasswordContext)
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);


  const [formData, setFormData] = useState({
    password: "",
    confirm_password: "",
    email:forgetPasswordEmail,
  });
  const [validationErrors, setValidationErrors] = useState({});

  const navigate = useNavigate();
  
  if(!forgetPasswordEmail ){
    navigate('/forget')
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let errors = {};
  
    if (!formData.password) {
      errors = { ...errors, password: "Please enter your password." };
    } else {
      let passwordError = "";
  
      if (formData.password.length < 8) {
        passwordError += "Password must contain at least 8 Characters. ";
      }
      if (!/(?=.*[a-z])/.test(formData.password)) {
        passwordError += "Password must contain at least one LowerCase letter. ";
      }
      if (!/(?=.*[A-Z])/.test(formData.password)) {
        passwordError += "Password must contain at least one UpperCase letter. ";
      }
      if (!/(?=.*[!@#$%^&*])/.test(formData.password)) {
        passwordError += "Password must contain at least one symbol (!@#$%^&*). ";
      }
  
      if (passwordError !== "") {
        errors = { ...errors, password: passwordError.trim() };
      }
    }
  
    if (!formData.confirm_password || formData.confirm_password !== formData.password) {
      errors = { ...errors, confirm_password: "Passwords do not match." };
    }
  
    setValidationErrors(errors);
  
    return Object.keys(errors).length === 0; // Return true if there are no errors
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      if (validationErrors.password) {
        toast.error(validationErrors.password);
      }
      return;
    }
    setLoader(true)
    // Handle form submission logic here
    api("post", "/change_password", formData)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Password Changed Successfully");
          navigate("/login");
        }
      })
      .catch((error) => {
        toast.error("Password is not matched");
        console.error("Error:", error);
      })
      .finally(()=>setLoader(false));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
   
   <>
   <div className="confirm_otp_form">
    <div className="confirm_otp_top">
          <span>Change Password </span>
          <div className="input_field header_margin">
          <div className="password-input-container">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Enter New Password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            <i
              className={`password-toggle-icon ${
                showPassword ? "fas fa-eye-slash" : "fas fa-eye"
              }`}
              onClick={togglePasswordVisibility}
            />
          </div>
          {validationErrors.password && (
            <div className="error-message" style={{ color: "#D00D4F" }}>{validationErrors.password}</div>
          )}
            <div className="password-input-container">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Confirm password"
              name="confirm_password"
              value={formData.confirm_password}
              onChange={handleChange}
            />
            <i
              className={`password-toggle-icon ${
                showPassword ? "fas fa-eye-slash" : "fas fa-eye"
              }`}
              onClick={togglePasswordVisibility}
            />
          </div>
          {validationErrors.confirm_password && (
            <div className="error-message" style={{ color: "#D00D4F" }}>{validationErrors.confirm_password}</div>
          )}
        </div>
        <div className="confirm_otp_button">
        <button  onClick={handleSubmit} disabled={!formData.password || !formData.confirm_password} >Change Password</button>
        </div>
              
          {loader &&
            <div class="loader"/>
          }

          </div>
          </div>
   </>

  )
}

export default Confirmpassword
