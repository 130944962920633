import React from 'react';
import Logo from '../../images/logo_circle.png';
import desktop from '../../images/desktop.png';
import "./ipad.css"
const Ipad = () => {
  return (
    <div className="ipad-container">
      <img src={Logo} alt="Logo" className="logo" />
      <div className="message">
        TennerLocalDeals.com is only available for use on a mobile device. Please visit on your phone.
      </div>
      <div className="images">
        <img src={desktop} alt="Desktop Not Supported" className="desktop-image" />
        
      </div>
    </div>
  );
};

export default Ipad;
