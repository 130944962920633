import React, { useState, useContext } from "react";
import api from "../../api";
import Backbtn from "../../images/back.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {ForgetPasswordContext} from '../../utils/context'

const Forget = () => {
  const { setForgetPasswordEmail } = useContext(ForgetPasswordContext)
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    api("post", "/forgot_password", formData)
      .then((response) => {
        if (response.status === 200) {
          setForgetPasswordEmail(formData.email)
          navigate("/Otpsec");
        }
      })
      .catch((error) => {
        toast.error("Email not found in our database")
        console.error("Error:", error);
      });
  };
  return (
    <>
      <div className="forget_form">
        <Link to="/main">
          <img width='41' src={Backbtn} alt="" />
        </Link>
        <div className="forget_top">
          <span>Forget Password?</span>
          <p>
            Please enter the email address linked with your account and you will
            receive an email to reset your password.
          </p>
        </div>
        <div className="forget_input_fields">
          <input
            type="email"
            placeholder="Enter Your Email"
            name="email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, [e.target.name]: e.target.value})
            }
          />
        </div>
        <div className="forget_button">
          <button onClick={handleForgotPassword} disabled={!formData.email}>Forgot Password</button>
        </div>

        <div className="forget_form_end">
          <p>
            Remember Password?
            <Link to="/login" className="linkRedirect">
              <span> Sign In</span>
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default Forget;
