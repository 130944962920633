import React, { useEffect, useState } from 'react';
import api from "./api";
import Router from 'react-easy-router';
import { useLocation, useNavigate } from 'react-router-dom';
import tennerRoutes from './routes/route';
import Signin from './components/signinpage/Signin';
import { ToastContainer } from 'react-toastify';
import { ForgetPasswordContext } from './utils/context';
import { isMobileDevice } from './utils/device';
import 'react-toastify/dist/ReactToastify.css';
import { UpdateStore } from './StoreContext';
import Ipad from './components/ipad/Ipad';
import Maintenance from './components/Maintenance';
import { toast } from "react-toastify";

function App() {
  const [forgetPasswordEmail, setForgetPasswordEmail] = useState();
  const [reloaded, setReloaded] = useState(true);
  
  //check maintenanceMode 
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  useEffect(() => {
      api("get", "/check-maintenance")
      .then((response) => {
        setMaintenanceMode(response.data.maintenance_mode === 1);
      })
      .catch((error) => {
        toast.error("There was an error fetching the maintenance mode!");
        console.error('There was an error fetching the maintenance mode!', error);
      });
  }, []);
  //----check maintenanceMode

  const { pathname } = useLocation();
  console.log("🚀 ~ App ~ pathname:", pathname);
  const nav = useNavigate();

  const updateStore = UpdateStore();

  const checkAuth = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const user = localStorage.getItem("user");
      if (user) {
        updateStore({ loggedIn: true, user: JSON.parse(user) });
      }
      return { success: true, role: "user" };
    } else {
      console.log("got here ?");
      if (pathname === "/main") {
        nav("/main");
      } else if (pathname === "/") {
        nav("/main");
      } else if (pathname === "/login") {
        nav("/login");
      } 
      else if (pathname === "/verify-email") {
        nav("/verify-email");
      }
      // else if (pathname === "/contactus") {
      //   nav("/contactus");
      //   } 
         else if (pathname === "/signup") {
        nav("/signup");
      } else if (pathname === "/forget") {
        nav("/forget");
      } else if (pathname === "/Otpsec") {
        nav("/Otpsec");
      } else if (pathname === "/Confirmpassword") {
        nav("/Confirmpassword");
      } else if (pathname !== "/Confirmpassword" || pathname !== "/Otpsec" || pathname !== "/forget" || pathname !== "/signup" || pathname !== "/verify-email" || pathname !== "/login" || pathname !== "/") {
        nav("/login");
      }
      return false;
    }
  };
  
  useEffect(() => {
    document.title = "Tenner Local Deals";
    checkAuth();
  }, [pathname]);

  useEffect(() => {
    const handleResize = () => {
      const mobile = isMobileDevice();
      if (!mobile && !reloaded) {
        window.location.reload();
        setReloaded(true);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [reloaded]);

  //check MAintenance mode
  if (maintenanceMode) {
    return <Maintenance />;
  }

  if (!isMobileDevice()) {
    if (!reloaded) {
      window.location.reload();
      setReloaded(true);
    }
    return <Ipad />;
    
  }

  return (
    <>
      <ToastContainer />
      <ForgetPasswordContext.Provider value={{ forgetPasswordEmail, setForgetPasswordEmail }}>
        <Router routes={tennerRoutes} isAuthenticated={checkAuth} />
      </ForgetPasswordContext.Provider>
    </>
  );
}

export default App;
